@import '../colors.scss';


.bg-naranja {
    background-color: $naranja !important;
}


.userMain .userBlock{
    float: left;
    width: 100%;
    box-shadow: 0px 0px 23px -3px #ccc;
    padding-bottom: 12px;
    margin-bottom: 30px;
    overflow: hidden;
    background:#fff;
    

}
.userMain .userBlock .backgrounImg{
    float: left;
    overflow: hidden;
    width: 100%;
    background: $primary;
    height: 77px;
}
.userMain .userBlock .backgrounImg img{
    width: auto;
}
.userMain .userBlock .userImg{
    text-align: center;
}
.userMain .userBlock .userImg img{
    width: 80px;
    height: 80px;
    margin-top: -39px;
    border-radius: 100%;
    border: 5px solid #fff;
    
}
.userMain .userBlock .userDescription{
    text-align: center;
}
.userMain .userBlock .userDescription h5{
    margin-bottom: 2px;
    font-weight: 600;
}
.userMain .userBlock .userDescription p{
    margin-bottom: 5px;
}
.userMain .userBlock .userDescription .btn{
    padding: 0px 23px 0px 23px;
    height: 22px;
    border-radius: 0;
    font-size: 12px;
    background: #0198dd;
    color: #fff;
}
.userMain .userBlock .userDescription .btn:hover{
    
    opacity:0.7;
}

.userMain .userBlock .followrs{
    display: inline-flex;
    margin-right: 10px;
    border-right: 1px solid #ccc;
    padding-right: 10px;
}
.userMain .userBlock .followrs .number{
    font-size: 15px;
    font-weight: bold;
    margin-right: 5px;
    margin-top: -1px;
}