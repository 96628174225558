.asignado_mantenimiento{
	background: rgb(4, 128, 190)  !important;
	color:white;
}
.base{
	background: #d4ebf2 !important;
	color: black;
}
.descanso{
	background: #ffff00  !important;
	color: #000000;
}





.asignado_conservacion{
	background: rgb(255, 165, 0)  !important;
}
.base_conservacion{
	background: #d4ebf2 !important;
	color: black;
}
.descanso_conservacion{
	background: #ffff00  !important;
	color: black;
}





.asignado_limpieza{
	background: #20c997  !important;
}
.base_limpieza{
	background: #d4ebf2 !important;
	color: black;
}
.descanso_limpieza{
	background: #ffff00  !important;
	color: black;
}





