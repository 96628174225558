

.pasara_por_compras{
	background: #ffc0c0;
	margin: 10px 0;
	padding: 15px 30px;
}


  .disabled-row {
    background-color: #e9ecef; /* Gris claro */
    color: #6c757d; /* Texto gris */
    // pointer-events: none; /* Evita que se interactúe con la fila */
    opacity: 0.6; /* Efecto visual de deshabilitado */
  }
  .hide-row {
  	display: none;
  }

  /* Estilo para deshabilitar botones dentro de una fila */
  .disabled-row button {
    // pointer-events: none; /* Deshabilitar la interacción con el botón */
    opacity: 0.5;
  }



.proveedor_si{
	background: #dddd5e !important;
}

.proveedor_no{
	background: #5ecbdd !important;
}




// '1', 'CREANDO SOLICITUD'
.estatus_requisicion_1{
	background: #F0E68C !important;
}
// '2', 'APROBACIÓN'
.estatus_requisicion_2{
	background: #FFD700 !important;
}
// '3', 'EN ATENCIÓN'
.estatus_requisicion_3{
	background: #87CEEB !important;
}
// '4', 'EN COMPRAS'
.estatus_requisicion_4{
	background: #32CD32 !important;
}
// '5', 'EN TRANSFERENCIAS'
.estatus_requisicion_5{
	background: #4682B4 !important;
}
// '6', 'EN ESPERA COMPROBACIÓN'
.estatus_requisicion_6{
	background: #FFA500 !important;
}
// '7', 'CERRADA'
.estatus_requisicion_7{
	background: #808080 !important;
}
// '8', 'PENDIENTE'
.estatus_requisicion_8{
	color: white !important;
	background: #8d6b00 !important;
}
// '9', 'CANCELADA'
.estatus_requisicion_9{
	background: #FF6347 !important;
}


.facturado{
	background: #fff3b1 !important;
}