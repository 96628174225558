.idestatus_herramienta_1{
	background: #28a745 !important;
	color:white;
}
.idestatus_herramienta_2{
	background: #007bff !important;
	color:white;
}
.idestatus_herramienta_3{
	background: #ffc107 !important;
	color:white;
}
.idestatus_herramienta_4{
	background: #17a2b8 !important;
	color:white;
}
.idestatus_herramienta_5{
	background:  #dc3545 !important;
	color:white;
}
