.ROLE_ADMIN {
  background: #000000 !important; // Negro para el administrador, simbolizando autoridad y control.
}

.ROLE_RESPONSABLE_TECNICOS {
  background: #4A90E2 !important; // Azul, asociado con la tecnología y la confianza.
  border: none !important;
}

.ROLE_RESPONSABLE_LIMPIEZA {
  background: #0ACF83 !important; // Verde esmeralda, simbolizando la supervisión de la limpieza.
}

.ROLE_RESPONSABLE_OPERACION {
  background: #F5A623 !important; // Naranja, relacionado con la acción y la energía operativa.
}

.ROLE_TECNICO {
  background: #2cb596 !important; // Verde claro, representando habilidad y precisión técnica.
}

.ROLE_LIMPIEZA {
  background: #7ED321 !important; // Verde, simbolizando frescura y limpieza.
}

.ROLE_CONTABILIDAD {
  background: #82976d !important; // Verde claro, asociado con finanzas y estabilidad.
}

.ROLE_RECURSOS_HUMANOS {
  background: #FF9500 !important; // Naranja, relacionado con la energía de las relaciones interpersonales.
}

.ROLE_OBRA {
  background: #D0021B !important; // Rojo, representando la construcción, obra y esfuerzo físico.
}

.ROLE_SISTEMAS {
  background: #007AFF !important; // Azul oscuro, asociado con la tecnología y la innovación.
}


.ROLE_RESPONSABLE_PRESUPUESTO {
  background: #8B572A !important; // Marrón, representando seriedad y manejo de recursos.
}

.ROLE_RECURSOS_MATERIALES {
  background: #F8E71C !important; // Amarillo, relacionado con el suministro y el flujo de recursos.
}

.ROLE_SHEI {
  background: #BD10E0 !important; // Violeta, representando seguridad y protocolos especiales.
}

.ROLE_ATENCION_CLIENTES {
  background: #F56A79 !important; // Rosa, relacionado con el trato humano y la atención al cliente.
}

.ROLE_BOUTIQUE {
  background: #FF2D55 !important; // Rosa intenso, para la moda y productos exclusivos.
}

.ROLE_CLIENTE {
  background: #9B9B9B !important; // Gris, neutro, para representar a los clientes externos.
}

.ROLE_RECLUTAMIENTO {
  background: #FFA500 !important; // Naranja fuerte, asociado a la búsqueda y adquisición de talento.
}

.AUXILIAR_OBRA {
  background: #FF7F50 !important; // Coral, relacionado con la asistencia en tareas pesadas.
}

.ROLE_JEFE_ADMIN {
  background: #2C3E50 !important; // Azul marino, simbolizando liderazgo en administración.
}

.ROLE_MARKETING {
  background: #E67E22 !important; // Naranja brillante, representando creatividad y estrategia.
}

.RESIDENTE_OBRA {
  background: #E74C3C !important; // Rojo fuerte, representando la supervisión en obra.
}

.ROLE_ALMACEN {
  background: #34495E !important; // Azul acero, simbolizando organización y almacenamiento.
}

.ROLE_COMPRAS {
  background: #8E44AD !important; // Púrpura, relacionado con la gestión y adquisiciones.
}

.ROLE_COMPROBACIONES {
  background: #F39C12 !important; // Amarillo dorado, asociado con la revisión y verificación.
}

.ROLE_CEO {
  background: #2C3E50 !important; // Azul oscuro, representando el liderazgo de la empresa.
}



.navbar.ROLE_ADMIN {
  background-color: #000000 !important; // Negro para el administrador, simbolizando autoridad y control.
}

.navbar.ROLE_RESPONSABLE_TECNICOS {
  background-color: #4A90E2 !important; // Azul, asociado con la tecnología y la confianza.
}

.navbar.ROLE_RESPONSABLE_LIMPIEZA {
  background-color: #0ACF83 !important; // Verde esmeralda, simbolizando la supervisión de la limpieza.
}

.navbar.ROLE_RESPONSABLE_OPERACION {
  background-color: #F5A623 !important; // Naranja, relacionado con la acción y la energía operativa.
}

.navbar.ROLE_TECNICO {
  background-color: #2cb596 !important; // Verde claro, representando habilidad y precisión técnica.
}

.navbar.ROLE_TECNICO .navbar-nav .nav-link{
  color: white !important;
}

.navbar.ROLE_LIMPIEZA {
  background-color: #7ED321 !important; // Verde, simbolizando frescura y limpieza.
}


.navbar.ROLE_LIMPIEZA .navbar-nav .nav-link{
  color: #000000 !important;
}


.navbar.ROLE_CONTABILIDAD {
  background-color: #82976d !important; // Verde claro, asociado con finanzas y estabilidad.
}

.navbar.ROLE_RECURSOS_HUMANOS {
  background-color: #FF9500 !important; // Naranja, relacionado con la energía de las relaciones interpersonales.
}

.navbar.ROLE_OBRA {
  background-color: #D0021B !important; // Rojo, representando la construcción, obra y esfuerzo físico.
}

.navbar.ROLE_SISTEMAS {
  background-color: #007AFF !important; // Azul oscuro, asociado con la tecnología y la innovación.
}


.navbar.ROLE_RESPONSABLE_PRESUPUESTO {
  background-color: #8B572A !important; // Marrón, representando seriedad y manejo de recursos.
}

.navbar.ROLE_RECURSOS_MATERIALES {
  background-color: #F8E71C !important; // Amarillo, relacionado con el suministro y el flujo de recursos.
}

.navbar.ROLE_RECURSOS_MATERIALES .navbar-nav .nav-link{
  color: #000000 !important;
}

.navbar.ROLE_SHEI {
  background-color: #BD10E0 !important; // Violeta, representando seguridad y protocolos especiales.
}

.navbar.ROLE_ATENCION_CLIENTES {
  background-color: #F56A79 !important; // Rosa, relacionado con el trato humano y la atención al cliente.
}

.navbar.ROLE_BOUTIQUE {
  background-color: #FF2D55 !important; // Rosa intenso, para la moda y productos exclusivos.
}

.navbar.ROLE_CLIENTE {
  background-color: #9B9B9B !important; // Gris, neutro, para representar a los clientes externos.
}

.navbar.ROLE_RECLUTAMIENTO {
  background-color: #FFA500 !important; // Naranja fuerte, asociado a la búsqueda y adquisición de talento.
}

.navbar.AUXILIAR_OBRA {
  background-color: #FF7F50 !important; // Coral, relacionado con la asistencia en tareas pesadas.
}

.navbar.ROLE_JEFE_ADMIN {
  background-color: #2C3E50 !important; // Azul marino, simbolizando liderazgo en administración.
}

.navbar.ROLE_MARKETING {
  background-color: #E67E22 !important; // Naranja brillante, representando creatividad y estrategia.
}

.navbar.RESIDENTE_OBRA {
  background-color: #E74C3C !important; // Rojo fuerte, representando la supervisión en obra.
}

.navbar.ROLE_ALMACEN {
  background-color: #34495E !important; // Azul acero, simbolizando organización y almacenamiento.
}

.navbar.ROLE_COMPRAS {
  background-color: #8E44AD !important; // Púrpura, relacionado con la gestión y adquisiciones.
}

.navbar.ROLE_COMPROBACIONES {
  background-color: #F39C12 !important; // Amarillo dorado, asociado con la revisión y verificación.
}

.navbar.ROLE_CEO {
  background-color: #2C3E50 !important; // Azul oscuro, representando el liderazgo de la empresa.
}
